/* Final results screen */

import React, { useState, useEffect, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import ReactFacebookPixel from 'react-facebook-pixel';
// import ReactTiktokPixel from 'react-tiktok-pixel';
import { useRouteMatch } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import fingerprint from 'fingerprintjs';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import ReactHtmlParser from 'react-html-parser';

import colors from '../../config/colors';
import layout from  '../../config/layout';
import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { sortResults } from './sort';
import { cleanStringForComparison, createSubmitData } from '../../utils/helper';
import { copyArray } from '../../utils/copyArray';
import { getNameByID } from '../../utils/getNamesByIDs';

import ConfettiLayout from '../../ui/layout/ConfettiLayout';
import ResultsCardSimple from '../../components/ResultsCardSimple';
import UnlockPremiumCard from '../../components/UnlockPremiumCard';
import { P, PSmall, H2, H3, Label, HintText } from '../../ui/typography/Typography';
import { Tag } from '../../ui/tag/Tag';
import { ColorBg } from '../../ui/bg/BgStyles';
import LoadingScreen from '../LoadingScreen';
import PathwaysInfo from '../../components/PathwaysInfo';
import SelectBar from '../../components/SelectBar';
import { PrimaryButton, LCGPurpleButton, GrayButton } from '../../ui/buttons/Buttons';
import IconListCheckmark from '../../assets/feature-tick.png';
import RestartFlow from '../RestartFlow';
import EmailResults from './EmailResults';
import UpdateDetails from './UpdateDetails';
import OverviewModal from './OverviewModal';
import IconAdd from '../../assets/icon-add.svg';
import AutocompleteField from '../../ui/form/AutocompleteField';
import Tooltip from '../../components/Tooltip';
import { facebookPixelInit } from '../../facebookPixelInit';
import Insights from '../Insights';
import IconPadlock from '../../assets/icon-padlock.svg';

const FinalResults = () => {

  const [{ user_id, loading, window_top, context }, dispatch] = useStateValue();
  const history = useHistory();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ loadingMsg, setLoadingMsg ] = useState();

  const [ sortType, setSortType ] = useState('jobs');
  const [ initialSortType, setInitialSortType ] = useState(null);

  const [ careerToAdd, setCareerToAdd ] = useState({value: "", placeholder: "e.g. detective, software engineer...", error: null});
  const [ careerToAddID, setCareerToAddID ] = useState(null);
  const [ careerToAddName, setCareerToAddName ] = useState(null);

  const [ careersAutocompleteOptions, setCareersAutocompleteOptions ] = useState(null);

  const [ resultsRefs, setResultsRefs ] = useState([]);
  const [ deletedCareerIDs, setDeletedCareerIDs ] = useState([]);

  const [ hasFiredTrackingEvents, setHasFiredTrackingEvents ] = useState(false);

  const showNumPathways = false;

  let showSortButtons = true;
  if (context && (context.live_in_the_uk === false || context.num_pathways === 0 || (context.final_results && context.final_results.length === 0))) {
    showSortButtons = false;
  }
  let disableSortButtons = false;
  if (context && context.are_some_careers_locked) {
    disableSortButtons = true;
  }

  let showAddDeleteCareers = false;
  if (context && !context.are_some_careers_locked && context.pathways_available && context.final_results.length > 0) {
    showAddDeleteCareers = true;
  }

  let sortValues = [];
  if (context && context.num_pathways > 0) {
    sortValues.push({label: 'Time', id: 'time'});
    sortValues.push({label: 'Cost', id: 'cost'});
    sortValues.push({label: 'Salary', id: 'salary'});
  }
  if (context && context.include_skills_match === true) {
    sortValues.push({label: 'Skills', id: 'skills'});
  }
  sortValues.push({label: 'Jobs', id: 'jobs'});

  const updateSortType = (sortType) => {
    setSortType(sortType);
    let initialSelectedValue = null;
    for (let i=0; i<sortValues.length && initialSelectedValue === null; i++) {
      if (sortValues[i].id === sortType) {
        initialSelectedValue = sortValues[i];
      }
    }
    setInitialSortType(initialSelectedValue);
  };

  const createBenefitsList = () => {
    let benefitsList = [];
    benefitsList.push("See all your top careers 🔥");
    if (context && context.num_pathways > 0) {
      let personalisedBenefit = "";
      if (showNumPathways) {
        personalisedBenefit += context.num_pathways.toLocaleString() + " personalised";
      } else {
        personalisedBenefit += "Personalised";
      }
      personalisedBenefit += " to-do lists to help you into your careers with time, cost and starting salaries 🥳";
      benefitsList.push(personalisedBenefit);
    }
    benefitsList.push("Sort your careers by time, cost, salary and availability of jobs 🤓");
    if (context && context.include_skills_match === true) {
      benefitsList.push("Discover which careers you have transferable skills in 💪");
    }
    benefitsList.push("Results emailed to you, so you can access them anytime 💌");
    benefitsList.push("Watch day in the life videos for half our careers 🎥");
    benefitsList.push("Browse our entire careers database by industry, time/cost to get into, starting salaries and more 🔎");
    return benefitsList;
  };
  const benefitsList = createBenefitsList();

  let match = useRouteMatch("/:userId/results");

  useGetScreenData('results', setIsLoading, match.params.userId);

  useEffect(() => {
    // Logging
    if (context && context.final_results && !hasFiredTrackingEvents) {
      setHasFiredTrackingEvents(true);

      // Facebook
      facebookPixelInit(user_id);
      ReactFacebookPixel.trackCustom(
        'Final_Results_Shown',
        { num_results: context.final_results.length, external_id: user_id },
        { eventID: context.fb_event_id_complete_registration }
      );
      ReactFacebookPixel.track(
        'CompleteRegistration',
        { amount: 0.01, currency: 'GBP', value: context.final_results.length, external_id: user_id },
        { eventID: context.fb_event_id_final_results_shown }
      );

      // Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'completeRegistration'
      });

      // Tiktok
      // ReactTiktokPixel.track('Registration');
    }

    // Sort type
    let initialSortType = 'jobs';
    if (context) {
      if (context.sort_order) {
        initialSortType = context.sort_order;
      } else if (context.num_pathways > 0) {
        if (context.are_some_careers_locked) {
          initialSortType = 'salary';
        } else {
          initialSortType = 'time';
        }
      }
    }
    updateSortType(initialSortType);

    // Set the careersAutocompleteOptions
    if (context && context.careers_autocomplete_options) {
      setCareersAutocompleteOptions(context.careers_autocomplete_options);
    }

    // Create a ref for each result (to scroll to when appropriate, such as when a career is added)
    if (context && context.final_results) {
      let resultsRefsToUpdate = [];
      for (let i=0; i<context.final_results.length; i++) {
        if (!resultsRefsToUpdate[context.final_results[i].career_id]) {
          resultsRefsToUpdate[context.final_results[i].career_id] = createRef();
        }
      }
      setResultsRefs(resultsRefsToUpdate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    // Scroll to top
    if ( (window_top || window_top === 0) && (!loading && !isLoading && context && context.final_results)) {
      window.scrollTo({top: window_top});
      dispatch({type: 'update', values: {window_top: null}});
    }

    // Scroll to career added if there is one
    if (context && context.final_results && resultsRefs && resultsRefs[context.career_id_added] && resultsRefs[context.career_id_added].current) {
      resultsRefs[context.career_id_added].current.scrollIntoView({
        behavior: "smooth",
      });
      context.career_id_added = null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isLoading]);

  const openCareerPage = (item)  => {
    const submitData = createSubmitData({
      user_id: match.params.userId,
      career_id: item.career_id,
    })
    axios.post('/api/v1/exploreCareerClicked', submitData);

    dispatch({type: 'update', values: {window_top: window.scrollY}})
    history.push(`/${user_id}/career/${item.career_url}`);
  };

  const openPaymentPage = (item)  => {
    const submitData = createSubmitData({
      user_id: match.params.userId,
    })
    axios.post('/api/v1/unlockCareersClicked', submitData);
    history.push(`/${user_id}/payment`);
  };

  const deleteCareer = (item, logEvent)  => {
    // Update locally
    let copy = copyArray(deletedCareerIDs);
    if (!copy.includes(item.career_id)) {
      copy.push(item.career_id);
      setDeletedCareerIDs(copy);
    }

    // Update autocomplete options
    if (careersAutocompleteOptions) {
      let copy2 = copyArray(careersAutocompleteOptions);
      let found = false;
      for (let i=0; i<copy2.strings.length && !found; i++) {
        if (copy2.strings[i].id === item.career_id) {
          copy2.strings[i].added = false;
          setCareersAutocompleteOptions(copy2);
          found = true;
        }
      }
    }

    // Update on server
    const submitData = createSubmitData({
      user_id: match.params.userId,
      career_id: item.career_id,
    })
    axios.post('/api/v1/deleteCareer', submitData);

    // Log Amplitude event
    logEvent('Delete Career', {
      career: getNameByID(item.career_id, careersAutocompleteOptions),
    });
  };

  const undoDeleteCareer = (item, logEvent)  => {
    // Update locally
    let copy = copyArray(deletedCareerIDs)
    copy.splice(copy.indexOf(item.career_id), 1);
    setDeletedCareerIDs(copy);

    // Update autocomplete options
    if (careersAutocompleteOptions) {
      let copy2 = copyArray(careersAutocompleteOptions);
      let found = false;
      for (let i=0; i<copy2.strings.length && !found; i++) {
        if (copy2.strings[i].id === item.career_id) {
          copy2.strings[i].added = true;
          setCareersAutocompleteOptions(copy2);
          found = true;
        }
      }
    }

    // Update on server
    const submitData = createSubmitData({
      user_id: match.params.userId,
      career_id: item.career_id,
    })
    axios.post('/api/v1/undoDeleteCareer', submitData);

    // Log Amplitude event
    logEvent('Undo Delete Career', {
      career: getNameByID(item.career_id, careersAutocompleteOptions),
    });
  };

  const handleSortChange = (item, logEvent) => {
    setSortType(item.id);

    // Track
    logEvent('Sort Order Changed', {sort_order: item.label});
    const submitData = createSubmitData({
      user_id: match.params.userId,
      sort_order: item.id,
    })
    axios.post('/api/v1/trackSortOrderChange', submitData);
  }

  const sortFinalResults = (finalResults) => {
    let results = finalResults;

    if (sortType) {
      results = sortResults(finalResults, sortType);
    }

    return results;
  };

  const getMatchedCareer = (item) => {
    const itemLowerCase = (item !== null) ? cleanStringForComparison(item.toLowerCase()) : item;
    let matchedCareer = null;
    if (careersAutocompleteOptions) {
      for (let i=0; i<careersAutocompleteOptions.strings.length && !matchedCareer; i++) {
        if (cleanStringForComparison(careersAutocompleteOptions.strings[i].name.toLowerCase()) === itemLowerCase) {
          matchedCareer = {
            id: careersAutocompleteOptions.strings[i].id,
            name: careersAutocompleteOptions.strings[i].name
          };
        } else {
          for (let j=0; j<careersAutocompleteOptions.strings[i].aka.length && !matchedCareer; j++) {
            if (cleanStringForComparison(careersAutocompleteOptions.strings[i].aka[j].name.toLowerCase()) === itemLowerCase) {
              matchedCareer = {
                id: careersAutocompleteOptions.strings[i].id,
                name: careersAutocompleteOptions.strings[i].name + " (" + careersAutocompleteOptions.strings[i].aka[j].name + ")"
              };
            }
          }
        }
      }
    }
    return matchedCareer;
  };

  const onSearchFieldBlur = (item) => {
    let matchedCareer = getMatchedCareer(item);
    if (!matchedCareer && item !== '') {
      let copy = copyArray(careerToAdd);
      copy.error = "This is not a valid career";
      setCareerToAdd(copy);
    }
  };

  const handleSearchFieldChange = (value) => {
    let copy = copyArray(careerToAdd);

    copy.error = null;
    copy.value = value.name || value;

    setCareerToAdd(copy);
    prepData(copy);
  };

  const prepData = (userCareer) => {
    let userCareerToAddID = null;
    let userCareerToAddName = null;
    let matchedCareer = getMatchedCareer(userCareer.value);
    if (matchedCareer) {
      userCareerToAddID = matchedCareer.id;
      userCareerToAddName = matchedCareer.name;
    }
    setCareerToAddID(userCareerToAddID);
    setCareerToAddName(userCareerToAddName);
  };

  const validateForm = () => {
    let isValid = true;
    if (careerToAddID == null || careerToAddID < 0) isValid = false;
    return isValid;
  };

  const addCareer = (logEvent) => {
    setLoadingMsg("Adding " + careerToAddName + " to your results");
    setIsLoading(true);

    const submitData = createSubmitData({
      user_id: match.params.userId,
      career_id: careerToAddID,
    })

    axios.post('/api/v1/addCareer', submitData).then((res) => {
      dispatch({type: 'updateResponse', data: res.data});
      setTimeout(() => {
        setIsLoading(false);
        setLoadingMsg(null);
      }, 2000);
      logEvent('Added Career (Search)', {
        career: careerToAddName,
      });
    });
  };

  const backToQualitySelection = () => {
    setIsLoading(true);
    const userFingerprint = (new fingerprint()).get();
    const userAgent = navigator.userAgent;
    axios.post('/api/v1/backToQualitySelectionPhase', {
      user_id: user_id,
      fingerprint: userFingerprint,
      user_agent: userAgent,
    }).then((resp) => {
      setIsLoading(false);
      if (resp.status === 200) {
        dispatch({type: 'updateResponse', data: resp.data});
        history.push(`/${resp.data.user_id}/${resp.data.screen.name}/${resp.data.screen.context.source_url ? resp.data.screen.context.source_url : ''}`);
      }
    })
  };

  const afterEmailSent = () => {
    setLoadingMsg(context.email_loading_message);
    setIsLoading(true);
    setTimeout(() => {
      context.emails_sent = true; // Set locally as the updated value from the server wouldn't have been returned yet
      setIsLoading(false);
      setLoadingMsg(null);
    }, 3000);
  };

  if (loading || isLoading || !context || !context.final_results ) return <LoadingScreen text={loadingMsg} />;

  let benefitsItemIndex = 0;

  const paid = context.amount_paid_pence > 0;
  const canEmailResults = (context.final_results.length !== 0 && !context.are_some_careers_locked);
  const emailsSent = context.emails_sent || localStorage.getItem("emails_sent") === true || localStorage.getItem("emails_sent") === "true";

  let currentItemPositionInList = 0;

  let numCareersToShow = 0;
  if (context && context.final_results) {
    for (let i=0; i<context.final_results.length; i++) {
      if (!deletedCareerIDs.includes(context.final_results[i].career_id)) {
        numCareersToShow++;
      }
    }
  }

  let backgroundImageTopDesktop = 30;
  let backgroundImageTopMobile = 0;

  if (showSortButtons) {
    backgroundImageTopDesktop += 20;
    backgroundImageTopMobile += 50;
  }
  if (context && context.message_above_results && !context.are_some_careers_locked && context.final_results.length > 0) {
    backgroundImageTopDesktop += 15;
    backgroundImageTopMobile += 40;
  }
  if (canEmailResults) {
    backgroundImageTopDesktop += 90;
    backgroundImageTopMobile += 70;
  }
  backgroundImageTopDesktop += "px";
  backgroundImageTopMobile += "px";

  return (
    <>
      <MetaTags>
        <title>Final Results</title>
        <meta id="meta-description" name="description" content="Your final results" />
      </MetaTags>
      <ConfettiLayout
        title={context.final_results.length === 0 ? "You've not selected any careers" : (context.first_name ? context.first_name + "'s results" : "Your results")}
        subtitle={context.final_results.length === 0 ?
          "To progress to final results, you need to select a few careers.  Please click the button below to select new qualities and careers..." :
          ( !context.are_some_careers_locked && !context.emails_sent ? (
            "Keep your results to hand by emailing a link to yourself."
          ) : "" ) }
        hasConfetti={context.final_results.length > 0 && true}
      >
        <Amplitude eventProperties={{
          num_careers: context.final_results.length,
          are_some_careers_locked: context.are_some_careers_locked,
        }} userProperties={{user_id: user_id, gender: context.gender}}>
          {({ logEvent }) => (
            <>
              {/* {paid && (
                <SendMessage />
              )} */}
              {context && context.is_college && context.can_update_details ? (
                <UpdateDetails />
              ) : (
                canEmailResults && (
                <EmailResults setLoadingMsg={setLoadingMsg} handlePostEmailSent={afterEmailSent} emailPreviouslySent={context.emails_sent} />
              ))}
              {context && context.are_some_careers_locked && (
                <LockedResultsTopContainer>
                  <InsightsContainer>
                    <Insights />
                  </InsightsContainer>
                  <Grid>
                    <Row>
                      <Col xs={12} md={8} mdOffset={2}>
                        {context && context.message_above_sort && (<MessageAboveResults>{ReactHtmlParser(context.message_above_sort)}</MessageAboveResults>)}
                        <TextCenter pb_desktop="52px" pb_mobile="32px">
                          <LCGPurpleButton onClick={() => {openPaymentPage()}}>Unlock your full results</LCGPurpleButton>
                        </TextCenter>
                      </Col>
                    </Row>
                  </Grid>
                </LockedResultsTopContainer>
              )}
              {showSortButtons && (
                <GridStyledForSort>
                  {context && !context.are_some_careers_locked && (
                    <OverviewModal />
                  )}
                  <SortContainer>
                    <SortLabel h5>Sort by</SortLabel>
                    <SelectBar
                      values={sortValues}
                      handleChange={(item) => handleSortChange(item, logEvent)}
                      selectedValue={initialSortType}
                      disabled={disableSortButtons}
                    />
                  </SortContainer>
                </GridStyledForSort>
              )}
              <GridStyled hasSort={showSortButtons} nopadding={context.final_results.length === 0}>
                {context && context.final_results.length > 0 && <Bg hasSort={showSortButtons} hideBG={numCareersToShow <= 2} topDesktop={backgroundImageTopDesktop} topMobile={backgroundImageTopMobile} />}
                <Row>
                  <LogOnMount eventType="View Final Results">
                    <Col xs={12} md={8} mdOffset={2}>
                      {context && context.message_above_results && !context.are_some_careers_locked && context.final_results.length > 0 && (<MessageAboveResults>{ReactHtmlParser(context.message_above_results)}</MessageAboveResults>)}
                      {context && context.final_results && (sortFinalResults(context.final_results).map(item => (
                        <div key={`result_${item.career_id}`}>
                          {context && context.are_some_careers_locked && currentItemPositionInList === 2 && (
                            <CustomCard>
                              <CustomCardTitle align="center">Unlock all {context.final_results.length} careers</CustomCardTitle>
                              <CustomCardText align="center">Unlock all your careers{context && context.num_pathways > 0 && (<>, along with {showNumPathways && <b>{context.num_pathways}</b>} personalised to-do lists into them</>)} {context.offer_name && (<>with our {context.offer_name} offer (expires soon!)</>)}</CustomCardText>
                              <BenefitsListSection>
                                {benefitsList.map(item => (
                                  <Row middle="xs" key={benefitsItemIndex++}>
                                    <Col xs={2} md={2}>
                                      <TickColumnContents><Image src={IconListCheckmark} alt="" /></TickColumnContents>
                                    </Col>
                                    <Col xs={10} md={10}>
                                      <PSmall>{item}</PSmall>
                                    </Col>
                                  </Row>
                                ))}
                                <Row middle="xs" key={benefitsItemIndex++}>
                                  <Col xs={2} md={2}></Col>
                                  <Col xs={10} md={10}><PSmall>...and more!</PSmall></Col>
                                </Row>
                              </BenefitsListSection>
                              <TextCenter>
                                <LCGPurpleButton onClick={() => {openPaymentPage()}}>Unlock your full results</LCGPurpleButton>
                              </TextCenter>
                            </CustomCard>
                          )}
                          <div ref={(resultsRefs[item.career_id]) ? resultsRefs[item.career_id] : null}>
                            {!deletedCareerIDs.includes(item.career_id) && (
                              <ResultsCardSimple
                                key={`result_${item.career_id}`}
                                imageUrl=""
                                title={item.career_name}
                                ctaLabel="Explore"
                                handleCtaClick={() => { if (context.are_some_careers_locked) { openPaymentPage() } else { openCareerPage(item)  } }}
                                ctaImage={context.are_some_careers_locked ? IconPadlock : null}
                                textUnderButton={context.are_some_careers_locked && currentItemPositionInList < 2 ? "Upgrade to view your personalised to-do list to get into this career, along with your transferable skills and more" : null}
                                blur={context.are_some_careers_locked && currentItemPositionInList++ >= 2}
                                trash={showAddDeleteCareers && context.package !== "standard"}
                                handleTrashClick={() => deleteCareer(item, logEvent)}
                              >
                                <TagWrap>
                                  {item.labels.map(label => (
                                    <Tag key={`${item.career_id}_${label.key}`} color={label.positive ? colors.LCG_GREEN: colors.LCG_DARK_PURPLE} bgColor={label.positive ? colors.LCG_GREEN_FADE : colors.LCG_DARK_PURPLE_FADE}>{label.name}</Tag>
                                  ))}
                                  {item.is_skills_match && (
                                    <Tag key="skills_match" color={colors.LCG_DARK_PURPLE} bgColor={colors.LCG_DARK_PURPLE_FADE}>STRONG SKILLS MATCH</Tag>
                                  )}
                                </TagWrap>
                                <PSmall align="center">{item.career_description}</PSmall>
                                {item.num_pathways > 0 && (
                                  <PathwaysInfo user_id={user_id} pathwaysSummary={item.pathways_summary} sortType={sortType} />
                                )}
                                {item.pathways_unavailable && (
                                  <PathwaysUnavailable bold align="center">Pathways unavailable.</PathwaysUnavailable>
                                )}
                              </ResultsCardSimple>
                            )}
                            {deletedCareerIDs.includes(item.career_id) && (
                              <ResultsCardSimple
                                key={`result_${item.career_id}`}
                              >
                                <CareerRemoveGrid>
                                  <Row>
                                    <Col xs={2} md={2}></Col>
                                    <CareerRemovedCol xs={8} md={8}>{item.career_name} removed</CareerRemovedCol>
                                      <UndoCareerRemovedCol xs={2} md={2} onClick={() => undoDeleteCareer(item, logEvent)}>Undo</UndoCareerRemovedCol>
                                  </Row>
                                </CareerRemoveGrid>
                              </ResultsCardSimple>
                            )}
                          </div>
                        </div>
                      )))}
                    </Col>
                  </LogOnMount>
                </Row>
              </GridStyled>
              {showAddDeleteCareers && (
                <Grid>
                  <Row>
                    <Col xs={10} xsOffset={1} md={8} mdOffset={2}>
                      <AddMoreCareersSection>
                        <AddMoreCareersTitle align="center">Add more careers</AddMoreCareersTitle>
                        <P align="center">You can add more careers to your results by searching for a specific career, or browsing all the careers in our database</P>
                        {context && context.package !== "standard" && (
                          <>
                            <AddMoreCareersSubSection pt="10px">
                              <Label>Search</Label>
                              <HintText>Type a career and select from available options to add to your list</HintText>
                              <AutocompleteField
                                placeholder={careerToAdd.placeholder}
                                suggestions={careersAutocompleteOptions}
                                handleChange={it => handleSearchFieldChange(it)}
                                onBlur={e => onSearchFieldBlur(e.target.value)}
                                error={careerToAdd.error}
                              />
                              <AddCareerButton pt="20px">
                                {validateForm() ? (
                                  <LCGPurpleButton onClick={() => {addCareer(logEvent)}}>Add career</LCGPurpleButton>
                                ) : (
                                  <Tooltip text="Type and select a valid career in the box above">
                                    <GrayButton disabled>Add career</GrayButton>
                                  </Tooltip>
                                )}
                              </AddCareerButton>
                            </AddMoreCareersSubSection>
                            <AddMoreCareersSubSection pt="20px">
                              <Label>Browse</Label>
                              <HintText>Browse all careers by time, cost, salary, industry and more...</HintText>
                              <AddCareerButton pt="12px">
                                <LCGPurpleButton onClick={() => {history.push(`/${user_id}/browse`);}}>Browse careers</LCGPurpleButton>
                              </AddCareerButton>
                            </AddMoreCareersSubSection>
                          </>
                        )}
                        {context && context.package === "standard" && (
                          <AddMoreCareersSubSection pt="10px">
                            <UnlockPremiumCard user_id={user_id} price={context.upgrade_to_premium_price} />
                          </AddMoreCareersSubSection> 
                        )}
                      </AddMoreCareersSection>
                    </Col>
                  </Row>
                </Grid>
              )}
              {context && context.are_some_careers_locked && (
                <TextCenter pt_desktop="52px" pt_mobile="32px" pb_desktop="52px" pb_mobile="32px">
                  <LCGPurpleButton onClick={() => {openPaymentPage()}}>Unlock your full results</LCGPurpleButton>
                </TextCenter>
              )}
              {context.final_results.length === 0 && (
                <ButtonContainer>
                  <PrimaryButton onClick={() => {backToQualitySelection()}}>Back to quality selection</PrimaryButton>
                </ButtonContainer>
              )}
              <RestartFlow logOut={true} areSomeCareersLocked={context.are_some_careers_locked} paid={paid} canEmailResults={canEmailResults} emailsSent={emailsSent} endpoint={context.endpoint} />
            </>
          )}
        </Amplitude>
      </ConfettiLayout>
    </>
  );
};

export default FinalResults;

const MessageAboveResults = styled(P)`
  text-align: center;
  padding-bottom: 32px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
  }
`

const CareerRemoveGrid = styled(Grid)`
  width: 100%;
`;

const CareerRemovedCol = styled(Col)`
  color: ${colors.TEXT_BODY};
  display: inline-block;
  text-align: center;
`;

const UndoCareerRemovedCol = styled(Col)`
  color: ${colors.LCG_LIGHT_PURPLE};
  font-weight: 700;
  margin: auto;
`;

const AddMoreCareersSection = styled.div`
  padding: 20px 0 40px 0;
`;

const AddMoreCareersTitle = styled(H3)`
  padding-top: 50px;
  background-image: url(${IconAdd});
  background-repeat: no-repeat;
  background-position: top center;
  margin-bottom: 10px;
`;

const AddMoreCareersSubSection = styled.div`
  margin: auto;
  max-width: 382px;
  padding-top: ${props => props.pt ? props.pt : '0px'};
`;

const AddCareerButton = styled.div`
  text-align: center;
  padding-top: ${props => props.pt ? props.pt : '0px'};
`;

const GridStyledForSort = styled(Grid)`
  position: relative;
  padding-right: 150px;

  @media (max-width: ${layout.breakpoints.MD}) {
    width: 100%;
    left: 0;
    padding-right: 0px;
  }
`;

const SortContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 12px 32px 0px;

  @media (max-width: ${layout.breakpoints.MD}) {
    justify-content: center;
    flex-direction: column;
  }
`;

const SortLabel = styled(H2)`
  margin: 0px 12px 0px 12px;
  padding-top: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    text-align: center;
    margin: 0px 12px 6px 12px;
  }
`;

const GridStyled = styled(Grid)`
  position: relative;
  padding-top: ${props => props.hasSort || props.nopadding ? '0' : '64px'};

  @media (max-width: ${layout.breakpoints.MD}) {
    width: 100%;
    left: 0;
    padding-top: ${props => props.hasSort || props.nopadding ? '0' : '35px'};
  }
`;

const Bg = styled.div`
  position: absolute;
  width: 80%;
  left: 10%;
  top: ${props => props.topDesktop};
  height: 100%;
  z-index: -1;

  @media (max-width: ${layout.breakpoints.MD}) {
    width: 100%;
    left: 0;
    top: ${props => props.topMobile};
  }

  &:before {
    ${ColorBg};
    background-position: center top;
    background-size: 100% auto;
    content: "";
    display: block;
    height: 850px;
    position: sticky;
    top: 50px;
    width: 100%;
    padding-bottom: 200px;
    z-index: -1;
    ${props => props.hideBG  && `display:none;`};

    @media (max-width: ${layout.breakpoints.MD}) {
      background-size: 120% auto;
    }
  }
`;

const TagWrap = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: 20px 0 32px;
  }
`;

const CustomCard = styled.div`
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};
  margin-bottom: 32px;
  background-color: ${colors.LCG_PINK_LIGHT};
  padding: 32px;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 24px 16px;
  }
`;

const CustomCardTitle = styled(H3)`
  margin-bottom: 4px;
`;

const TextCenter = styled.div`
  text-align: center;
  ${props => props.pt_desktop  && `padding-top:${props.pt_desktop};`};
  ${props => props.pb_desktop  && `padding-bottom:${props.pb_desktop};`};

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => props.pt_mobile  && `padding-top:${props.pt_mobile};`};
    ${props => props.pb_mobile  && `padding-bottom:${props.pb_mobile};`};
  }
`;

const CustomCardText = styled(PSmall)`
  padding-top: 12px;
  padding-bottom: 20px;
  max-width: 460px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 420px;
  }
`;

const BenefitsListSection = styled.div`
  padding-bottom: 20px;
  max-width: 500px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 360px;
  }
`;

const TickColumnContents = styled(P)`
  text-align: center;
  font-weight: 700;
  padding-top: 6px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 4px;
  }
`;

const Image = styled.img`
  max-width: 24px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 16px;
  }
`;

const PathwaysUnavailable = styled(PSmall)`
  width: 100%;
  margin-bottom: 20px;
  color: ${colors.TEXT_DARK};
`;

const LockedResultsTopContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 8px;
    padding-right: 8px;
  }
`

const InsightsContainer = styled.div`
  padding-top: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 20px;
  }
`
